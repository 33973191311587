.footer {
  background-color: $black;
  color: $white;

  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  a {
    color: $white;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    &:hover {
      color: darken($white, 20%);
    }
  }
}

.footer__links {
  display: flex;
  justify-content: flex-end;
  align-items: end;


  .btn {
    margin-left: 1rem;
  }
}
