.heading__teaser {
  color: $white;
  text-transform: uppercase;
  font-family: $font-family-logo;
  font-size: 4vw;
  line-height: 0.8;
  text-shadow: 0 0 2px $gray-900;

  @media (min-width: 1921px) {
    font-size: 3.5vw;
  }

  .heading--cue {
    color: $tun_yellow;
  }
}

$column-gap: 2rem;

.grid {
  grid-column-gap: $column-gap;
  grid-row-gap: $column-gap;
  display: grid;
  justify-content: stretch;
  width: 100%;

  grid-template-columns: 1fr;

  @include media-breakpoint-up(md) {
    padding: 2rem 0rem;
    grid-template-columns: 1fr 1fr;
  }
}

.grid__teaser {
  justify-self: stretch;
  display: flex;
}

.teaser {

  display: flex;
  flex-direction: column;
  color: $gray-900;
  line-height: 1;
  box-shadow: 0 15px 45px -9px rgba(255, 255, 255, 0.2);
  color: $white;
  background: $gray-900;
  padding: 0.3rem;

  &.teaser--hero {
    padding: 0.3rem;
    color: $gray-900;
    background: transparentize($white, 0.2);
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &.teaser--page {
    padding: 0.3rem;
    color: $gray-900;
    background: transparent;
    flex-direction: row;
    box-shadow: none;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }
}
.teaser__image {
  display: flex;

  @include media-breakpoint-up(lg) {
    min-height: 460px;
  }

  .popup__links {
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
  }

  .img-logo {
    width: 100%;
    height: inherit;
    align-self: flex-start;
  }

  .img-center {
    max-width: 100%;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: auto;
      max-width: fit-content;
      display: flex;
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
  }
}

.teaser--hero, .teaser--page {
  .teaser__image {
    margin-right: 0.25rem;
    flex: 1;
    max-width: 45%;

    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin-right: 0;
    }
  }
}
.teaser--page {
  .teaser__image {
    display: block;
    min-height: auto;
    position: relative;
    overflow: hidden;
    width: 45%;
  }
}

.teaser__content {
  flex: 1;
  display: flex;

  flex-direction: column;
}

.teaser__article {
  display: flex;
  padding-top: 1rem;
  flex: 2;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    flex: 1;
  }
}

.teaser__datetime {
  text-align: center;
  font-size: 1.0rem;
  flex: 1 0 0;
  font-weight: 300;
  line-height: 1.4;

  @include media-breakpoint-down(md) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 0.5rem;
    font-size: 1.2rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.4rem;
    padding-right: 1rem;
  }

  strong {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.teaser__text {
  @include media-breakpoint-up(md) {
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 1.5rem;
  }
}

.teaser--hero,
.teaser--page {
  .teaser__text {
    @include media-breakpoint-up(md) {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      padding-right: 1.5rem;
    }
  }
}

.teaser__title {
  font-size: 1.2rem;
  font-weight: 300;

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.7rem;
  }

  a {
    color: $white;

    &:hover,
    &:active {
      text-decoration: none;
      color: $gray-200;
    }
  }
}

.teaser--hero,
.teaser--page {
  .teaser__title {
    a {
      color: $gray-900;

      &:hover, &:active {
        color: $gray-800;
      }
    }
  }
}

.teaser__intro {
  line-height: 1.2;
  font-size: 0.8rem;
  font-weight: 300;

  @include media-breakpoint-up(lg) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.0rem;
  }
}

.teaser--hero .teaser__intro {
  font-size: 0.9rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.1rem;
  }
}

.teaser--page .teaser__intro {
  font-size: 0.9rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.1rem;
  }
}

.teaser__link {
  text-transform: uppercase;
  align-self: flex-end;
  text-align: right;
  display: block;
}

.teaser__movie {
  background: $gray-900;
  color: $white;
  display: flex;
  flex: 1;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 0.5rem;
    margin-top: 1rem;
  }
}

.teaser__moviecue {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
  color: $tun_yellow;

  @include media-breakpoint-up(md) {
    text-align: right;
    width: 17%;
    padding-right: 0.6rem;
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.7rem;
    padding-right: 1rem;
  }
}

.teaser__movieposter {
  order: 0;
  width: 15%;
  flex: 1;
  overflow: hidden;
  height: 100%;
  margin-right: 0.15rem;
  display: flex;
  flex-direction: column;

  img {
    max-width: 100%;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  @include media-breakpoint-up(md) {
    width: 17%;
    padding-right: 0.5rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0.5rem;
  }
}

.teaser__movietext {
  padding-top: 1rem;
  line-height: 1.2;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
  font-size: 0.8rem;

  @include media-breakpoint-up(md) {
    font-size: 0.85rem;
    padding-right: 1.5rem;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.9rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.0rem;
  }

  h3 {
    font-weight: 200;
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 0;

    @include media-breakpoint-up(xl) {
      font-size: 1.7rem;
    }
  }
}

.teaser__movieintro {
  padding-bottom: 0.5rem;
}


.teaser__moviemeta {
  margin-bottom: 0.5rem;
  color: $gray-500;
}

.teaser__movielink {
  color: $tun_yellow;
  font-size: 1.3rem;
  margin-top: auto;
  display: inline-block;
  margin-left: auto;
  order: 2;

  @include media-breakpoint-up(md) {
    text-align: right;
    font-size: 1.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.8rem;
  }

  &:hover {
    color: darken($tun_yellow, 10%);
  }
}
