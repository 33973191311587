.logo {
  font-family: $font-family-logo;
  text-align: center;

  &.logo--main {
    color: $tun_yellow;
    font-size: 34vh;
    word-break: break-all;
    line-height: 0.75;
  }

  &.logo--sub {
    color: $tun_yellow;
    margin-top: 0;
    margin-bottom: 2.2rem;
  }

  &.logo--sub,
  &.logo--text {
    font-size: 3.9vh;
    line-height: 1;
  }

  &.logo--text {
    color: $gray-900;
    font-size: 3.2vh;
  }
}

.sidebar-sticky {
  position: sticky;
  height: 100vh;
  padding-top: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  background: $white;
}
