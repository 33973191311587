$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/tun";
@import "styles/cookiebanner";
@import "~bootstrap/scss/bootstrap";
@import "styles/swiper/pagination";
@import "styles/swiper/slider";
//@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/application.scss";
@import "styles/logo";
@import "styles/headings";
@import "styles/program";
@import "styles/footer";
@import "styles/text";
@import "styles/listing";
// @import "styles/slideshow/all";
@import "styles/accounts";
@import "styles/gallery/caption";
@import "styles/teaser";
@import "styles/moreteaser";

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-size: 1.0rem;
  background: $gray-900;

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
    //font-size: 1.1rem;
  }
}

/* make room for the nav bar */
div.row.section[id] {
  padding-top: 70px;
  margin-top: -35px;
  margin-bottom: 2rem;
}

div.row.intro[id] {
  padding-top: 40px;
}

.modal.modal-fullscreen {
  padding-right: 0px !important;
  height: 100% - 6%;
  background: transparentize($white, 0.2);
  top: 6%;

  @include media-breakpoint-up(md) {
    width: (100% - 15% - 6%);
    left: 15% + 3%;
  }

  @include media-breakpoint-up(lg) {
    width: (100% - 12% - 6%);
    left: 12% + 3%;
  }

  @include media-breakpoint-up(xl) {
    width: (100% - 10% - 6%);
    left: 10% + 3%;
  }
}

.modal-fullscreen .close {
  display: inline-block;
  text-align: right;
  align-self: flex-end;
  padding-right: 1rem;
  margin: 0;
  margin-bottom: -1rem;
  font-size: 4rem;
  position: fixed;
  z-index: 1;
}



.navbar.navbar-tun {
  font-family: $font-family-logo;
  .dropdown-item {
    font-size: 1.0rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    font-size: 2.0rem;
    .dropdown-item {
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 2.2rem;
    .dropdown-item {
      font-size: 1.8rem;
    }
  }
}

.badge {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.main {
  position: relative;
  min-height: 100vh;
  background-color: $tun_blue;
  background: linear-gradient(0deg, rgba($tun_blue, 0.4), rgba($tun_blue, 0.4)), var(--main-background);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;

  .container {
    margin-top: 3rem;
  }

  @include media-breakpoint-down(md) {
    .container {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-up(md) {
    flex: 0 0 (100% - 15%);
    max-width: (100% - 15%);
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 (100% - 12%);
    max-width: (100% - 12%);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 0 (100% - 10%);
    max-width: (100% - 10%);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 15%;

  @include media-breakpoint-up(lg) {
    width: 12%;
  }

  @include media-breakpoint-up(xl) {
    width: 10%;
  }
}

.news-page,
.text-page {
  .main-wrapper {
    background: transparentize($white, 0.2);
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
}

@include media-breakpoint-up(md) {
  .md\:columns-2 {
    columns: 2;
    column-gap: 3rem;
    column-fill: balance;
  }
}
