.moreteaser {
  margin-top: 0.6rem;
  display: flex;
}

.moreteaser_cue {
  color: $tun_yellow;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.2;
  font-family: $font-family-logo;
  text-shadow: 0 0 2px $gray-900;
  flex: 1;

  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  @include media-breakpoint-up(md) {
    text-align: right;
    font-size: 1.9rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2.4rem;
  }
}

.moreteaser__list {
  display: flex;
  flex-direction: column;
  margin-left: 1.2rem;

  width: 53%;
  max-width: 53%;
  flex: 0 0 53%;
}

.moreteaser__item {
  background: $gray-900;
  color: $white;
  font-weight: 300;
  font-size: 0.9rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;

  &:hover, &:active {
    color: $tun_yellow;
    text-decoration: none;
  }

  strong {
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
