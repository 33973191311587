.application__submit {
  margin-top: 1rem;
}

.application__form-buttons {
  padding-top: 2rem;
}

.application__collaborator {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  .application__collaborator-name {
    font-weight: bold;
  }
}

.application__form fieldset {
  margin-bottom: 2rem;
}

.panel-inner-block {
  flex: 1;
}

.content .application--list {
  margin-top: 0;
}

.was-validated :invalid+.invalid-feedback,
.was-validated :invalid+.invalid-tooltip,
.is-invalid+.invalid-feedback,
.is-invalid+.invalid-tooltip {
  display: block;
}

.error-hint-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  display: inline-block;
}

.application__form {

  .required {
    label:not(.custom-control-label):after {
      font-weight: bold;
      content: "*";
    }

    $field-required: rgb(225, 175, 46);

    input[type="text"],
    input[type="email"],
    textarea {
      border-color: $field-required;

      &:focus {
        box-shadow: 0 0 0 0.2rem transparentize(darken($field-required, 5%), 0.75);
      }
    }

  }

  .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .form-control.is-invalid {
    border-color: #dc3545;
  }

  .application__questionnaire .form-group {
    margin-bottom: 2rem;
  }

  .submit-hint {
    margin-top: 0.5rem;
    line-height: 1.15;
  }

  .label.is-required::after {
    content: '*';
    padding-left: 0.1rem;
  }

  .file-field {
    flex-direction: column;
    display: inline-flex;
    flex-wrap: nowrap;
    word-break: break-all;

  }

  .file-container {
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
  }

  .file--filled {

    .file-field {
      margin-right: 1rem;
      width: 50%;
    }

    .file-input {
      left: 50%;
    }
  }

  .file--filled,
  .file--empty {
    .help {
      font-size: 1rem;
    }
  }
}

.application--accept {
  margin-bottom: 1rem;

  strong {
    vertical-align: top;
    display: none;
  }
}

.application--hint {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.help.is-before-input {
  margin-top: -0.5rem;
  font-size: 1rem;
}

.content p.help.is-before-input {
  margin-bottom: 0.5rem;
}


.flex-multicheckbox {
  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .custom-control.custom-checkbox {
    padding-right: 1.5rem;
  }
}
